import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const searchProperties = async ({ name }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/properties`,
    method: 'get',
    params: {
      name,
    },
  });

  return response.data.map((property) => ({ type: 'property', ...property }));
};
