import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const searchLocations = async ({ name }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/locations`,
    method: 'get',
    params: {
      name,
    },
  });

  return response.data.map((location) => ({ type: 'location', ...location }));
};
