import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import escapeRegExp from 'lodash/escapeRegExp';
import { Text } from '@qga/roo-ui/components';

const TextHighlighter = ({ text, highlightText }) => {
  if (!text) return '';
  if (!highlightText) return <Text>{text}</Text>;

  const regex = new RegExp(`(${escapeRegExp(highlightText)})`, 'gi');
  const parts = text.split(regex);

  return compact(parts).map((part, index) => {
    if (regex.test(part)) {
      return (
        <Text key={index} fontWeight="bold">
          {part}
        </Text>
      );
    }
    return <Text key={index}>{part}</Text>;
  });
};

TextHighlighter.propTypes = {
  text: PropTypes.string,
  highlightText: PropTypes.string,
};

TextHighlighter.defaultProps = {
  text: '',
  highlightText: '',
};

export default TextHighlighter;
